/* ================== general ===================== */

#home-page h2, #cultura-ciatos-page h2 {
    text-align: center;
    font-weight: normal;
    font-size: 1.5rem;
    margin-bottom: 30px;
}

#home-page .btn, #cultura-ciatos-page .btn {
    font-size: 0.9rem;
    font-weight: bold;
    padding: 0 20px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #330000;
    color: white;
    box-shadow: 0 10px 20px 10px rgba(21, 0, 0, 0.1);
    cursor: pointer;
    text-transform: uppercase;
}

#home-page .btn:hover, #cultura-ciatos-page .btn:hover {
    opacity: 0.9;
}

#home-page .hide {
    display: none;
}

#home-page .inactive {
    opacity: 0.5;
    cursor: default;
}

#home-page .inactive:hover {
    opacity: 0.5;
    cursor: default;
}

/* =================================== section 1 =================================== */

#home-page .section1 {
    height: 100vh;
    background: url(../../assets/img/background-section1.png);
    background-position: top right -45px;
    background-size: cover;
    background-repeat: no-repeat;
}

#home-page .section1 .content-wrapper {
    height: 100vh;
}

#home-page .section1 .content {
    height: 100%;
    padding: 0 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#home-page .section1 .content h1 {
    font-size: 1.7rem;
    font-weight: normal;
    text-align: center;
    margin-bottom: 30px;
}

#home-page .section1 .content p {
    font-size: 1.2rem;
    text-align: center;
    /* width: 516px; */
    margin-bottom: 30px;
}

#home-page .section1 .content .buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
}

#home-page .section1 a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

/* ========================================= section 2 ============================================== */

#home-page .section2 .content {
    padding: 30px 10px;
}

#home-page .section2 .image-wrapper {
    width: 100%;
    margin-bottom: 20px;
}

#home-page .section2 .content p {
    text-align: justify;
    font-size: 1rem;
}

/* ========================================= section 3 =============================================== */

#home-page .section3 .content {
    padding: 30px 10px 0;
    text-align: center;
}

#home-page .section3 .content p {
    text-align: justify;
    margin-bottom: 20px;
}

#home-page .section3 .content img {
    width: 100%;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.02);
    border: 1px solid #ddd;
    border-radius: 10px;
}

#home-page .section3 .content img:hover {
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);
}

/* ========================================= section 4 =============================================== */

#home-page .section4 {
    background: url(../../assets/img/section4-background.png);
    background-position: bottom;
    background-size: cover;
}

#home-page .section4 .content {
    padding: 30px 10px;
}

#home-page .section4 .content p {
    text-align: justify;
    margin-bottom: 20px;
}

#home-page .section4 .content .flex-container {
    display: flex;
    flex-wrap: wrap;
}

#home-page .section4 .content .flex-item {
    flex: 0 0 100%;
    margin-bottom: 20px;
}

#home-page .section4 .content .flex-item .card-content {
    width: 180px;
    height: 240px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.02);
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
}

#home-page .section4 .content .flex-item .title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

#home-page .section4 .content .flex-item .body {
    text-align: center;
    width: 160px;
    /* margin */
}

#home-page .section4 .content .flex-item img {
    width: 60px;
    margin-bottom: 10px;
}

#home-page .section4 .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* ========================================= section 5 =============================================== */

#home-page .section5 {
    background: #fdfdfd;
}

#home-page .section5 .content {
    padding: 30px 10px;
}

#home-page .section5 .content p {
    text-align: justify;
    margin-bottom: 20px;
}

#home-page .section5 .content .flex-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

#home-page .section5 .content .flex-item {
    flex: 0 0 100%;
    margin-bottom: 40px;
}

#home-page .section5 .content .flex-item .card-content {
    width: 200px;
    margin: 0 auto;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#home-page .section5 .content .flex-item .title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

#home-page .section5 .content .flex-item .body {
    text-align: center;
    width: 170px;
}

#home-page .section5 .content .flex-item img {
    width: 50px;
}

#home-page .section5 .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* ====================================== section 6 =========================================== */

#home-page .section6 {
    background: url(../../assets/img/section6-background.png);
    background-repeat: no-repeat;
    background-position-y: top -100px;
}

#home-page .section6 .content {
    padding: 30px 10px;
}

#home-page .section6 .content .flex-item {
    flex: 0 0 100%;
    margin-bottom: 40px;
}

#home-page .section6 .content .card {
    background-color: white;
    width: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
}

#home-page .section6 .content .card.dark-bg {
    background-color: #330000;
    color: white;
}

#home-page .section6 .content .card.dark-bg .btn {
    background-color: white;
    color: #330000;
}

#home-page .section6 .content .card .title {
    color: inherit;
    margin-bottom: 15px;
    font-size: 1.2rem;
    font-weight: bold;
}

#home-page .section6 .content .card .price {
    color: inherit;
    margin-bottom: 35px;
    text-align: center;
}

#home-page .section6 .content .card .price-1 {
    color: inherit;
    font-size: 1.2rem;
}

#home-page .section6 .content .card .price-2 {
    color: inherit;
    font-size: 2.2rem;
}

#home-page .section6 .content .card .price-3 {
    color: inherit;
    font-size: 0.9rem;
}

#home-page .section6 .content .card ul {
    color: inherit;
    margin-bottom: 50px;
    list-style: none;
}

#home-page .section6 .content .card li {
    color: inherit;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 0.9rem;
}

#home-page .section6 .content .flex-item:last-child .price-2 {
    width: 90px;
    display: inline-block;
    font-size: 1.7rem;
    line-height: 1.6rem;
    position: relative;
}

#home-page .section6 .content .flex-item:last-child .price {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
}

#home-page .section6 .content .card .icon-wrapper {
    width: 23px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
}

/* ====================================== Section 7 =========================================== */

#home-page .section7 {
    background-color: #330000;
    padding: 100px 0;
}

#home-page .section7 h2 {
    color: white;
}

/* ====================================== section 8 =========================================== */

#home-page .section8 .content {
    padding: 30px 10px;
}

#home-page .section8 .content .wrapper {
    display: flex;
}

#home-page .section8 .content .flex-container {
    display: flex;
    flex-wrap: wrap;
}

#home-page .section8 .content .img {
    display: none;
}

#home-page .section8 .content .flex-item {
    flex: 0 0 100%;
    margin-bottom: 30px;
}

#home-page .section8 .content .card {
    background-color: white;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
}

#home-page .section8 .content .flex-item:first-child .card, #home-page .section8 .content .flex-item:last-child .card {
    background-color: #330000;
    color: white;
}

#home-page .section8 .content .card .title {
    color: inherit;
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-weight: bold;
}

#home-page .section8 .content .card .price {
    color: inherit;
    margin-bottom: 20px;
    text-align: center;
}

#home-page .section8 .content .card .price-1 {
    color: inherit;
    font-size: 1.2rem;
}

#home-page .section8 .content .card .price-2 {
    color: inherit;
    font-size: 2.2rem;
}

#home-page .section8 .content .card .price-3 {
    color: inherit;
    font-size: 0.9rem;
}

#home-page .section8 .content .card ul {
    color: inherit;
    margin-bottom: 50px;
    list-style: square;
    align-self: flex-start;
    margin-left: 30px;
}

#home-page .section8 .content .card li {
    color: inherit;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 0.9rem;
}

/* ======================================= section 9 ============================================ */

/* #home-page .section9 { */

/* margin-top: 50px; */

/* } */

#home-page .section9 .content {
    padding: 0 10px;
}

#home-page .section9 .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: rgba(255, 255, 255, 0.1); */
    border-radius: 10px;
    padding: 30px 0;
    max-width: 900px;
    margin: 0 auto;
}

#home-page .section9 .carousel-wrapper {
    /* margin: 0 auto; */
    /* width: max-content; */
    /* border: 1px solid #222; */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.08);
}

#home-page .section9 .buttons {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    background: #fff;
    border-radius: 5px;
}

#home-page .section9 .buttons button {
    border-radius: 5px;
    border: none;
    height: 50px;
    width: 50px;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    box-sizing: border-box;
}

#home-page .section9 .buttons button:hover {
    cursor: pointer;
    border: 1px solid #555;
}

#home-page .section9 .buttons button {
    background: rgba(255, 255, 255, 0.1);
}

#home-page .section9 .buttons .label {
    display: none;
}

/* ====================================== breakpoints =========================================== */

@media only screen and (min-width: 400px) {
    /* section 2 */
    #home-page .section2 .content {
        padding: 30px 20px;
    }
    /* section 3 */
    #home-page .section3 .content {
        padding: 30px 20px 0;
    }
    /* section 4 */
    #home-page .section4 .content {
        padding: 30px 20px;
    }
    /* section 5 */
    #home-page .section5 .content {
        padding: 30px 20px;
    }
    /* section 6 */
    #home-page .section6 .content {
        padding: 30px 20px;
    }
    /* section 8 */
    #home-page .section8 .content {
        padding: 30px 20px;
    }
}

@media only screen and (min-width: 600px) {
    /* general */
    #home-page h2 {
        font-size: 2rem;
    }
    /* section 1 */
    #home-page .section1 .content {
        padding: 0;
        width: 400px;
        margin: 0 auto;
    }
    #home-page .section1 .content h1 {
        font-size: 2rem;
    }
    /* section 4 */
    #home-page .section4 .content .flex-item {
        flex: 0 0 50%;
        margin-bottom: 20px;
    }
    /* section 5 */
    #home-page .section5 .content .flex-item {
        flex: 0 0 50%;
    }
    /* section 6 */
    #home-page .section6 .content .flex-item {
        flex: 0 0 50%;
    }
    #home-page .section6 .content .flex-container {
        /* justify-content: space-around; */
        display: flex;
        flex-wrap: wrap;
    }
    /* section 8 */
    #home-page .section8 .content .flex-item {
        flex: 0 0 50%;
    }
    #home-page .section8 .content .flex-item:last-child, #home-page .section8 .content .flex-item:nth-child(3) {
        margin-bottom: 0px;
    }
    /* section 9 */
    #home-page .section9 .buttons button {
        width: 140px;
    }
    #home-page .section9 .buttons .label {
        display: unset;
    }
}

@media only screen and (min-width: 800px) {
    /* section 1 */
    #home-page .section1 {
        background-position: top right 0;
    }
    /* section 2 */
    #home-page .section2 .content {
        padding: 100px 0;
        width: 700px;
        margin: 0 auto;
    }
    /* section 3 */
    #home-page .section3 .content {
        padding: 100px 0;
        width: 700px;
        margin: 0 auto;
    }
    /* section 4 */
    #home-page .section4 .content {
        padding: 100px 0px;
        width: 700px;
        margin: 0 auto;
    }
    #home-page .section4 .content .flex-container {
        justify-content: space-around;
    }
    #home-page .section4 .content .flex-item {
        flex: 0 0 33.3333%;
        margin-bottom: 20px;
    }
    #home-page .section4 .btn-wrapper {
        justify-content: flex-end;
    }
    /* section 5 */
    #home-page .section5 .content {
        padding: 100px 0px;
        width: 700px;
        margin: 0 auto;
    }
    #home-page .section5 .content .flex-item {
        flex: 0 0 33.3333%;
    }
    #home-page .section5 .btn-wrapper {
        justify-content: flex-end;
    }
    /* section 6 */
    #home-page .section6 .content {
        padding: 100px 0px;
        width: 700px;
        margin: 0 auto;
    }
    /* section 8 */
    #home-page .section8 .content {
        padding: 100px 0px;
        width: 700px;
        margin: 0 auto;
    }
    /* section 9 */
    /* #home-page .section9 {
        display: block;
    } */
    #home-page .section9 .content {
        padding: 0 0 100px !important;
    }
    #home-page .section9 .buttons {
        margin-right: 30px;
    }
}

@media only screen and (min-width: 1024px) {
    /* section 1 */
    #home-page .section1 .content-wrapper {
        width: 900px;
        height: 100vh;
        margin: 0 auto;
    }
    #home-page .section1 .content {
        margin: 0;
        width: 400px;
    }
    #home-page .section1 .content h1 {
        font-size: 2.3rem;
        text-align: justify;
    }
    #home-page .section1 .content p {
        text-align: justify;
    }
    /* section 2 */
    #home-page .section2 .content {
        width: 900px;
    }
    #home-page .section2 .content .flex-container {
        display: flex;
        flex-direction: row-reverse;
    }
    #home-page .section2 .content .image-wrapper {
        flex: 1;
        height: 440px;
        margin-bottom: 0;
        background: url(../../assets/img/section2.jpg);
        background-position: center;
        background-size: cover;
    }
    #home-page .section2 .content .image-wrapper img {
        display: none;
    }
    #home-page .section2 .content p {
        flex: 1;
        padding-right: 20px;
    }
    /* section 3 */
    #home-page .section3 .content {
        width: 900px;
    }
    /* section 4 */
    #home-page .section4 .content {
        width: 900px;
    }
    #home-page .section4 .content .flex-container {
        justify-content: space-between;
    }
    #home-page .section4 .content .flex-item {
        flex: 0 0 25%;
        margin-bottom: 30px;
    }
    /* section 5 */
    #home-page .section5 .content {
        width: 900px;
    }
    #home-page .section5 .content .flex-container {
        justify-content: space-between;
    }
    /* section 6 */
    #home-page .section6 .content {
        width: 900px;
    }
    #home-page .section6 .content .flex-item {
        flex: 0 0 33.3333%;
    }
    /* section 8 */
    #home-page .section8 .content {
        width: 900px;
    }
    #home-page .section8 .content .img {
        display: block;
        width: 600px;
        height: 530px;
        background: url(../../assets/img/section8-cowroking.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    /* section 9 */
    /* #home-page .section9 .wrapper {
        width: 1000px;
    }
    #home-page .section9 .card {
        flex: 0 0 calc(16.6666% - 20px);
        margin: 15px 10px;
    } */
    #home-page .section9 .buttons button {
        height: 58px;
    }
}

@media only screen and (min-width: 1200px) {
    /* section 1 */
    #home-page .section1 .content-wrapper {
        width: 1100px;
    }
    #home-page .section1 .content {
        width: 550px;
    }
    #home-page .section1 .content h1 {
        font-size: 2.9rem;
    }
    #home-page .section1 .content .buttons {
        flex-direction: row;
        height: unset;
    }
    #home-page .section1 .content .buttons .btn {
        width: 220px;
    }
    /* section 2 */
    #home-page .section2 .content {
        width: 1100px;
    }
    #home-page .section2 .content .image-wrapper {
        height: 380px;
    }
    /* section 3 */
    #home-page .section3 .content {
        width: 1100px;
    }
    /* section 4 */
    #home-page .section4 .content {
        width: 1100px;
    }
    /* section 5 */
    #home-page .section5 .content {
        width: 1100px;
    }
    /* section 6 */
    #home-page .section6 .content {
        width: 1100px;
    }
    /* section 8 */
    #home-page .section8 .content {
        width: 1100px;
    }
    #home-page .section8 .content .flex-item {
        margin-bottom: 70px;
    }
    #home-page .section8 .content .img {
        height: 571px;
    }
}
.pictures {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pictures .slider {
    height: 420px;
    width: 100%;
    max-width: 600px;
    background-color: black;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
}

.pictures .image-wrapper {
    height: 100%;
    flex: 0 0 200%;
    text-align: center;
}

.pictures .image-wrapper img {
    height: 100%;
}

.pictures .btn-slider {
    position: absolute;
    top: calc(50% - 30px);
    left: 10px;
    height: 60px;
    width: 60px;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0.5;
    border: none;
    padding: 10px;
    border-radius: 5px;
    transition-duration: 200ms;
}

.pictures .btn-slider:last-child {
    right: 10px;
    left: unset;
}

.pictures .btn-slider:hover {
    cursor: pointer;
    opacity: 1;
}

.pictures .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 40px;
    width: 40px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0.5;
    border: none;
    border-radius: 20px;
    transition-duration: 200ms;
}

.pictures .btn-close:hover {
    cursor: pointer;
    opacity: 1;
}

@media only screen and (min-width: 800px) {
    .pictures .btn-slider {
        left: calc(50% - 370px);
    }
    .pictures .btn-slider:last-child {
        right: calc(50% - 370px);
        left: unset;
    }
    .pictures .btn-close {
        top: calc(50% - 250px);
        right: calc(50% - 350px);
    }
}
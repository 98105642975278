.fa {
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (min-width: 1024px) {
    .fa {
        flex-direction: row;
        max-width: 900px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1200px) {
    .fa {
        max-width: 1100px;
    }
}

.ta {
    width: 300px;
}

@media only screen and (min-width: 600px) {
    .ta {
        width: 450px;
    }
}

@media only screen and (min-width: 1024px) {
    .ta {
        display: flex;
        flex-direction: column;
        height: 270px;
        justify-content: space-between;
        padding-left: 20px;
    }
}

@media only screen and (min-width: 1200px) {
    .ta {
        height: 360px;
    }
}
#modal .btn-close {
    position: absolute;
    top: 9px;
    right: 13px;
    width: 15px;
    height: 15px;
    border: none;
    background: transparent;
    z-index: 1002;
}

#modal .btn-close:hover {
    cursor: pointer;
}

#modal #result {
    margin-top: 20px;
    font-size: 1.4rem;
}

#modal .success {
    color: blue;
}

#modal .failed {
    color: red;
}

#modal .hide {
    display: none;
}

#modal .btn-close img {
    width: 100%;
}

#modal {
    position: fixed;
    min-height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
}

#modal .custom-modal-frame {
    position: relative;
    height: 563px;
    width: 1050px;
    background: #fff;
    display: flex;
    border-radius: 11px;
    overflow: hidden;
}

#modal .form-wrapper {
    width: 650px;
    height: 100%;
    padding: 30px 40px 40px;
    background: url(../../assets/img/marca-d-agua-ciatos.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

#modal .horizontal {
    display: flex;
    margin-bottom: 5px;
}

#modal .field {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px;
}

#modal .field label {
    font-weight: bold;
}

#modal .field input {
    border: 1px solid #ddd;
    border-radius: 6px;
    height: 50px;
    padding: 0 20px;
    color: #222;
    background-color: white;
}

#modal .field textarea {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 10px 20px;
    color: #222;
}

#modal .field input:focus,
#modal .field textarea:focus {
    border: 1px solid rgb(87, 26, 22);
}

#modal .btn-modal {
    height: 55px;
    width: 130px;
    font-size: 0.9rem;
    font-weight: bold;
    border: none;
    border-radius: 7px;
    background-color: #330000;
    color: white;
    margin-left: 10px;
    margin-top: 5px;
    box-shadow: 0px 15px 15px 5px rgba(51, 0, 0, 0.2);
}

#modal .info-wrapper {
    width: 400px;
    height: 100%;
    background: #330000;
    padding: 40px 50px;
    color: white;
    font-weight: bold;
}

#modal .info-item {
    display: flex;
    margin-bottom: 30px;
}

#modal .info-item .img-wrapper {
    width: 30px;
}

#modal .info-wrapper .title {
    font-size: 1.5rem;
    margin-bottom: 30px;
    color: white;
}

#modal .info-wrapper p {
    color: white;
}

#modal .arvore {
    max-width: 300px;
    margin-top: 30px;
}

.single .article {
    padding-top: 80px;
}

.single .article .content {
    padding: 10px;
}

.single .article .title {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 30px;
    color: #222;
}

.single .article .image-wrapper {
    max-width: 100%;
    max-height: 300px;
    margin: 0 0 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.single .article .image-wrapper img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

.single .article .body {
    text-align: justify;
    color: #222;
}

.single .article .body p {
    margin-bottom: 15px;
    line-height: 1.5rem;
}

.single .article .body ul,
.single .article .body ol {
    margin: 0 0 20px 30px;
    line-height: 1.5rem;
}

.single .article .body h1,
.single .article .body h2,
.single .article .body h3,
.single .article .body h4,
.single .article .body h5,
.single .article .body h6 {
    margin: 20px 0 10px;
}

.single .article .body h1 {
    margin: 30px 0 20px;
    font-size: 1.3rem;
}

.single .article .body table {
    margin: 0 auto 30px;
}

.single .article .body table,
.single .article .body td,
.single .article .body tr {
    border: rgb(87, 26, 22) 1px solid;
    border-collapse: collapse;
}

.single .article .body td,
.single .article .body th {
    padding: 10px;
    text-align: start;
    font-size: 1rem;
    line-height: 1.3rem;
}

.single .article .body th {
    text-align: center;
    background-color: rgb(87, 26, 22);
    color: white;
}

.single .article .body th:not(:first-child) {
    border-left: 1px solid white;
}

.single .article .body tr td:first-child {
    min-width: 100px;
}

/* blockquote style		 */
.single .article .body blockquote {
    display: block;
    /* 		  border-width: 0 0 0 4px;
    border-style: solid;
    border-color: rgb(87, 26, 22); */
    padding-left: 20px;
    margin: 0 0 10px 150px;
    font-size: 0.9rem;
}

.single .article .body a {
    /* border-bottom: 3px solid rgba(87, 26, 22, 0.8); */
    text-decoration: underline;
    opacity: 0.9;
}

.single .article .body a:hover {
    opacity: 1;
}

@media only screen and (min-width: 600px) {
    .single .article .content {
        padding: 20px 0 0;
        max-width: 90%;
        margin: 0 auto;
    }

    .single .article .image-wrapper {
        max-height: 400px;
    }
}

@media only screen and (min-width: 800px) {
    .single .article .content {
        max-width: 80%;
    }

    .single .article .title {
        font-size: 2rem;
    }
}

@media only screen and (min-width: 1024px) {
    .single .article .image-wrapper {
        max-height: 700px;
    }
}

@media only screen and (min-width: 1200px) {
    .single .article .content {
        max-width: 1000px;
    }

    .single .article .title {
        font-size: 2rem;
    }
}

.ambiente.flex-item {
    flex: 0 0 100%;
    margin-top: 30px;
}

.ambiente .card {
    position: relative;
    height: 300px;
    width: 300px;
    background: burlywood;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
}

.ambiente .title {
    font-size: 1.5rem;
    color: white;
    margin: 0 auto 10px;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    transition-duration: 200ms;
}

.ambiente .title:hover {
    background-color: transparent;
    color: transparent;
    cursor: pointer;
}

.ambiente .card .image-wrapper {
    flex: 0 0 200%;
    /* width: 600px; */
    height: 100%;
    text-align: center;
}

.ambiente .card img {
    width: auto;
    max-height: 300px;
    margin: 0 auto;
}

@media only screen and (min-width: 650px) {
    .ambiente.flex-item {
        flex: 0 0 50%;
    }
}

@media only screen and (min-width: 1000px) {
    .ambiente.flex-item {
        flex: 0 0 33.3333%;
    }
}
.welcome {
    background: url(../../../assets/img/welcome-bg-new.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
}

.welcome .shadow {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding-top: 80px;
}

.welcome .content {
    max-width: 90%;
    color: white;
}

.welcome .title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: white;
}

.welcome .subtitle {
    font-size: 1rem;
    max-width: 80%;
    margin: 0 auto;
    color: white;
}

@media only screen and (min-width: 400px) {
    .welcome .content {
        width: 400px;
    }

    .welcome .title {
        font-size: 2.5rem;
    }

    .welcome .subtitle {
        font-size: 1.1rem;
    }
}

@media only screen and (min-width: 600px) {
    .welcome .content {
        width: 480px;
    }

    .welcome .title {
        font-size: 3rem;
    }

    .welcome .subtitle {
        font-size: 1.3rem;
    }
}

@media only screen and (min-width: 800px) {
    .welcome .content {
        width: 700px;
    }

    .welcome .title {
        font-size: 4rem;
    }

    .welcome .subtitle {
        font-size: 1.3rem;
        width: 70%;
    }
}

@media only screen and (min-width: 1100px) {
    .welcome .content {
        width: 1000px;
    }

    .welcome .title {
        font-size: 5rem;
    }

    .welcome .subtitle {
        font-size: 1.3rem;
        width: 70%;
    }
}
#privacy-policy .shadow {
    height: 100vh;
    width: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1005;
}

#privacy-policy .content {
    height: 500px;
    width: 800px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

#privacy-policy .btn-close {
    position: absolute;
    top: 14px;
    right: 20px;
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
}

#privacy-policy .btn-close:hover {
    cursor: pointer;
}

#privacy-policy .btn-close img {
    width: 20px;
}

#privacy-policy .header {
    height: 60px;
    background: #330000;
    display: flex;
    align-items: center;
    padding: 0 30px;
}

#privacy-policy .header p {
    color: white;
    font-size: 1.2rem;
}

#privacy-policy .body {
    overflow-y: scroll;
    padding: 20px 30px;
    background: url(../../assets/img/marca-d-agua-ciatos.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    overflow-y: scroll;
    text-align: justify;
    max-height: calc(100% - 60px);
}

#privacy-policy .body p {
    font-size: 1rem;
    margin-top: 10px;
    text-align: justify;
}

#privacy-policy .body p:last-child {
    margin-bottom: 20px;
}

#privacy-policy .body ul, #privacy-policy .body ol {
    font-size: 1rem;
    margin-top: 10px;
    margin-left: 30px;
}

#privacy-policy .body li {
    margin-bottom: 10px;
}

#privacy-policy .body h2 {
    color: #111;
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: unset !important;
    margin-top: 20px;
    text-align: start;
}

.ta .tile {
    display: flex;
    margin-top: 10px;
    height: 60px;
    border-radius: 3px;
    overflow: hidden;
    transition-duration: 200ms;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.ta .tile:hover {
    box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
    transform: translate(-2px, -2px);
    /* border: 1px solid #ccc; */
}

.ta .tile .content {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.ta .article-img {
    height: 100%;
    min-width: 60px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid #ccc;
}

.ta .tile .excerpt {
    display: none;
}

.ta .tile .title {
    font-size: 1rem;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
}

.ta .tile .info {
    font-size: 0.9rem;
    opacity: 0.8;
}

@media only screen and (min-width: 600px) {
    .ta .tile {
        height: 80px;
        margin-top: 30px;
    }

    .ta .article-img {
        min-width: 80px;
    }

    .ta .tile .title {
        font-size: 1.3rem;
    }
}

@media only screen and (min-width: 1024px) {
    .ta .tile {
        height: 80px;
        margin-top: 0px;
    }

    .ta a {
        margin-top: 20px;
    }

    .ta .article-img {
        min-width: 80px;
    }

    .ta .tile .title {
        font-size: 1.2rem;
    }
}

@media only screen and (min-width: 1200px) {
    .ta .tile {
        height: 100px;
    }

    .ta .article-img {
        min-width: 100px;
    }

    .ta .tile .title {
        font-size: 1.4rem;
    }
}

.carousel {
    min-width: 300px;
    max-width: 300px;
    height: 180px;
    background-color: #333;
    display: flex;
    overflow: hidden;
    position: relative;
}

.carousel .article {
    flex: 0 0 100%;
    transition-duration: 500ms;
}

.carousel .article a {
    display: flex;
    height: 100%;
    text-decoration: none;
}

.carousel .shadow {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: flex-end;
    padding: 10px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.carousel .title {
    font-size: 1.3rem;
    font-weight: bold;
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: white;
}

.carousel .info {
    font-size: 0.9rem;
    color: white;
}

.btn-arrow {
    position: absolute;
    top: calc(50% - 20px);
    height: 40px;
    width: 40px;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-arrow.left {
    left: 10px;
}

.btn-arrow.right {
    right: 10px;
}

.first {
    transform: translateX(0);
}

.second {
    transform: translateX(-300px);
}

.third {
    transform: translateX(-600px);
}

.arrow-icon {
    height: 25px;
}

@media only screen and (min-width: 600px) {
    .carousel {
        min-width: 450px;
        max-width: 450px;
        height: 270px;
    }

    .second {
        transform: translateX(-450px);
    }

    .third {
        transform: translateX(-900px);
    }

    .btn-arrow:hover {
        transform: scale(1.1);
        cursor: pointer;
        transition-duration: 200ms;
    }

    .carousel .title {
        font-size: 1.3rem;
        font-weight: bold;
        text-decoration: none;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

@media only screen and (min-width: 1200px) {
    .carousel {
        min-width: 600px;
        max-width: 600px;
        height: 360px;
    }

    .second {
        transform: translateX(-600px);
    }

    .third {
        transform: translateX(-1200px);
    }
}

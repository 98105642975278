.header .btn-mobile {
    height: 40px;
    width: 40px;
    border: none;
    background-color: transparent;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .btn-icon {
    width: 100%;
}

.header .shadow {
    position: absolute;
    height: calc(100vh - 80px);
    width: 100%;
    top: 80px;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
}

.header .menu {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    height: 100%;
    overflow: scroll;
    background: white;
}

.header .menu-item {
    color: #222;
    border: none;
    background: transparent;
    font-size: 1rem;
    text-align: start;
    text-decoration: none;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.hide {
    display: none;
}

@media only screen and (min-width: 900px) {
    .header .menu-mobile {
        display: none;
    }
}
@font-face {
  font-family: "BookAntiqua";
  src: url(./assets/fonts/BKANT.TTF);
}

@font-face {
  font-family: "BookAntiqua";
  src: url(./assets/fonts/ANTQUAI.TTF);
  font-style: italic;
}

@font-face {
  font-family: "BookAntiqua";
  src: url(./assets/fonts/ANTQUAB.TTF);
  font-weight: bold;
}

@font-face {
  font-family: "BookAntiqua";
  src: url(./assets/fonts/ANTQUABI.TTF);
  font-weight: bold;
  font-style: italic;
}

* {
  margin: 0;
  padding: 0;
  font-family: "BookAntiqua", sans-serif;
  color: #222;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fdfdfd;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
  height: auto;
}

.no-text-decoration {
  text-decoration: none;
}
/* #571A16 */

#blog-footer {
    background: linear-gradient(#330000, rgba(51, 0, 0, 0.9));
    padding: 50px 0 20px;
    position: relative;
    z-index: 500;
}

#blog-footer .social-media {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#blog-footer .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 5px 3px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 40px;
    padding: 20px 0;
    border-radius: 10px;
    transition-duration: 200ms;
    width: 120px;
    background: white;
}

#blog-footer .item:hover {
    transform: scale(1.1);
}

#blog-footer .social-icon {
    width: 60px;
    height: auto;
    margin-bottom: 20px;
}

#blog-footer .label {
    color: #571A16;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
}

#blog-footer .links p {
    color: white;
}

#blog-footer a {
    text-decoration: none;
}

#blog-footer .links a:hover {
    text-decoration: underline;
}

@media only screen and (min-width: 500px) {
    #blog-footer .social-media {
        padding: 0;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 400px;
        margin: 0 auto;
    }
    #blog-footer .item {
        margin: 0 20px 40px;
    }
}

@media only screen and (min-width: 800px) {
    #blog-footer .social-media {
        max-width: 700px;
        padding-bottom: 50px;
    }
    #blog-footer .item {
        margin: 0 20px;
    }
}

@media only screen and (min-width: 1000px) {
    #blog-footer .social-media {
        max-width: 900px;
    }
    #blog-footer .item {
        margin: 0 40px;
    }
}

#blog-footer .divider {
    height: 1px;
    width: 100%;
    background: rgba(255, 255, 255, 1);
}

#blog-footer .bottom {
    padding: 0px 0 20px;
}

#blog-footer .links {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}

#blog-footer .links p {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 15px;
}

#blog-footer .links a {
    color: white;
    margin-bottom: 10px;
}

#blog-footer .links a:hover {
    text-decoration: underline;
}

#blog-footer .logo {
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

#blog-footer .logo-img {
    max-width: 250px !important;
}

#blog-footer .copyright {
    text-align: center;
    color: white;
    padding-top: 20px;
}

@media only screen and (min-width: 800px) {
    #blog-footer .bottom {
        display: flex;
        flex-direction: row;
        width: 700px;
        margin: 0 auto;
    }
    #blog-footer .links.left-links {
        align-items: flex-start;
    }
    #blog-footer .links.right-links {
        align-items: flex-end;
    }
}

@media only screen and (min-width: 1200px) {
    #blog-footer .bottom {
        width: 1000px;
    }
    #blog-footer .logo-img {
        max-width: 400px;
    }
}
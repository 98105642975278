

.article-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 30px;
}

.loading {
    padding: 100px 0;
    text-align: center;
    color: #222;
}
.search-field {
    position: relative;
    display: none;
}

.search-field input {
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: none;
    height: 30px;
    padding: 0 10px;
}

.home-page .search-field .background-transparent,
/* .search-page .search-field .background-transparent, */
.category-page .search-field .background-transparent {
    color: #eee;
}

.search-field input:focus {
    border: 2px solid rgb(87, 26, 22);
    box-shadow: none;
}

.home-page .background-transparent .search-field input:focus,
/* .search-page .background-transparent .search-field input:focus, */
.category-page .background-transparent .search-field input:focus {
    border: 2px solid white;
    box-shadow: none;
}

.btn-search {
    position: absolute;
    top: calc(50% - 11px);
    right: 3px;
    border: none;
    width: 22px;
    height: 22px;
    background: transparent;
    opacity: 0.8;
}

.btn-search:hover {
    cursor: pointer;
    opacity: 1;
}

.btn-search img {
    width: auto;
    height: 100%;
}

@media only screen and (min-width: 600px) {
    .search-field {
        display: unset;
    }
}

.featured-links {
    position: fixed;
    top: calc(50% - 100px);
    left: 0;
    z-index: 502 !important;
}

.featured-links .social-link img {
    height: 100%;
    width: 100%;
}

.featured-links .social-link {
    height: 40px;
    width: 40px;
    padding: 8px;
    background: #330000;
    transition-duration: 200ms;
    box-shadow: 2px 0px 0px 0px rgba(255, 255, 255, 0.7);
}

.featured-links .social-link:hover {
    width: 50px;
}

.featured-links .social-link.first {
    border-top-right-radius: 5px;
}

.featured-links .social-link.last {
    border-bottom-right-radius: 5px;
}

.featured-email {
    position: fixed;
    bottom: 0;
    left: calc(50% - 165px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    background-color:  #330000;
    color: white;
    border-radius: 5px 5px 0 0;
    box-shadow: 0px -2px 0px 0px rgba(255, 255, 255, 0.7);
}

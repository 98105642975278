.card-link {
    margin-top: 20px;
}

.card-link .card {
    width: 280px;
    height: 330px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 3px;
    margin: 0 10px;
    transition-duration: 200ms;
}

.card-link .card:hover {
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.3);
}

.card-link .card .image-wrapper {
    height: 160px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
    margin: 0;
}

.card-link .card .image-wrapper img {
    min-width: 100%;
    height: 100%;
}

.card-link .card .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 25px 10px 0px;
    height: 170px;
}

.card-link .card .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.card-link .card .excerpt {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
}

.card-link .card .info {
    font-size: 0.8rem;
    position: absolute;
    bottom: 2px;
    width: 100%;
    height: 40px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;
    border-top: 1px solid #ccc;
}

.card-link .card .info-item {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #666;
}

.card-link .card .info .divider {
    width: 1px;
    height: 100%;
    background-color: #ccc;
}

.card-link .card .badge {
    height: 40px;
    width: 40px;
    position: absolute;
    top: -20px;
    left: calc(50% - 20px);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 50%;
}

.card-link .card .badge img {
    width: 70%;
}
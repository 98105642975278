.search-page .search-content {
    padding: 100px 20px 0;
}

.search-page .title {
    color: #222;
    text-align: center;
}

@media only screen and (min-width: 1000px){
    .search-page .search-content {
        max-width: 1000px;
        padding: 100px 0 0;
        margin: 0 auto;
    }
}

#use-terms .shadow {
    height: 100vh;
    width: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1005;
}

#use-terms .content {
    height: 500px;
    width: 800px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

#use-terms .btn-close {
    position: absolute;
    top: 14px;
    right: 20px;
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
}

#use-terms .btn-close:hover {
    cursor: pointer;
}

#use-terms .btn-close img {
    width: 20px;
}

#use-terms .header {
    height: 60px;
    background: #330000;
    display: flex;
    align-items: center;
    padding: 0 30px;
}

#use-terms .header p {
    color: white;
    font-size: 1.2rem;
}

#use-terms .body {
    overflow-y: scroll;
    padding: 20px 30px;
    background: url(../../assets/img/marca-d-agua-ciatos.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    overflow-y: scroll;
    text-align: justify;
    max-height: calc(100% - 60px);
}

#use-terms .body .item-termos-uso {
    max-width: 8px;
    margin: 8px 5px 0 0;
}

#use-terms .body .item {
    display: flex;
    margin-left: 20px;
}

#use-terms .body .link {
    font-size: 1rem;
    color: inherit;
    border-bottom: 3px solid rgb(87, 26, 22);
    padding: 0;
    opacity: 0.8;
}

#use-terms .body .link:hover {
    opacity: 1;
}

#use-terms .body .icon-wrapper {
    padding-top: 7px;
}

#use-terms .body p {
    font-size: 1rem;
    margin-top: 10px;
    line-height: 1.4rem;
}

#use-terms .body p:last-child {
    margin-bottom: 20px;
}

#use-terms .body ul {
    font-size: 1rem;
    margin-top: 10px;
    margin-left: 30px;
}

#use-terms .body h3 {
    color: #111;
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
}
/* ====================================== Footer =========================================== */

.main-footer {
    background-color: #252125;
    padding-top: 150px;
}

.main-footer .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding: 0px 10px;
}

.main-footer .content .image-wrapper {
    margin-bottom: 30px;
}

.main-footer .content .text {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.main-footer .content .text button, .main-footer .content .text a {
    color: white;
    margin-bottom: 10px;
    text-align: center;
    text-decoration: none;
    background: transparent;
    border: none;
    font-size: 1rem;
    text-align: start;
}

.main-footer .content .text button:hover, .main-footer .content .text a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.main-footer .content .text p {
    color: white;
    margin-bottom: 10px;
    text-align: center;
}

.main-footer .divider {
    height: 1px;
    width: 90%;
    background-color: #333;
    margin: 0 auto;
}

.main-footer .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100px;
}

.main-footer .bottom p {
    color: white;
}

.main-footer .bottom .links {
    color: white;
    position: absolute;
    right: 80px;
    width: 180px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.main-footer .bottom .links a:hover {
    opacity: 0.8;
}

/* ====================================== breakpoints =========================================== */

@media only screen and (min-width: 400px) {
    .main-footer .content {
        padding: 0 20px;
    }
}

@media only screen and (min-width: 800px) {
    .main-footer .content {
        flex-direction: row;
        justify-content: space-between;
    }
    .main-footer .content .text a {
        text-align: left;
    }
    .main-footer .content .text p {
        text-align: right;
    }
}

@media only screen and (min-width: 1024px) {
    .main-footer .content {
        width: 900px;
        padding: 0;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1200px) {
    .main-footer .content {
        width: 1100px;
        padding: 0;
        margin: 0 auto;
    }
}
.blog-form form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}

.blog-form .title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    color: #222;
}

.blog-form input {
    margin-bottom: 5px;
    background: white;
    border: 1px solid rgb(87, 26, 22);
    font-size: 1rem;
    padding: 10px;
}

.blog-form input:focus {
    border: 2px solid rgb(87, 26, 22);
    padding: 9px;
}

.blog-form button {
    width: max-content;
    align-self: center;
    background: white;
    border: 2px solid rgb(87, 26, 22);
    font-size: 1rem;
    padding: 10px 15px;
    transition-duration: 200ms;
    margin-top: 20px;
}

.blog-form button:hover {
    background: rgb(87, 26, 22);
    color: white;
    cursor: pointer;
}

.blog-form .result {
    text-align: center;
    margin-top: 10px;
    font-size: 1.2rem;
}

.blog-form .result.success {
    color: green;
}

.blog-form .result.failed {
    color: red;
}

.blog-form .spinner {
    margin: 10px auto 0;
}

@media only screen and (min-width: 600px) {
    .blog-form .title {
        font-size: 1.2rem;
    }

    .blog-form input {
        width: 300px;
        align-self: center;
    }
}

@media only screen and (min-width: 800px) {
    .blog-form .title {
        font-size: 1.6rem;
        margin-bottom: 20px;
    }

    .blog-form input {
        width: 500px;
        align-self: center;
    }

    .blog-form form {
        max-width: 80%;
        margin: 20px auto;
        padding: 40px 0;
    }
}

@media only screen and (min-width: 1200px) {
    .blog-form form {
        max-width: 1000px;
    }
}
.author-info {
    display: flex;
    height: 60px;
    width: max-content;
    margin-bottom: 30px;
}

.author-info .image-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.author-info .text {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #222;
}

.author-info .author {
    font-size: 1.1rem;
    font-weight: bold;
}

.author-info .date {
    opacity: 0.8;
    font-size: 0.9rem;
}
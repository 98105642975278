.wrapper {
    position: fixed;
    bottom: 20px;
    right: 100px;
    height: 60px;
    width: 60px;
    background-color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: url(../../assets/img/whatsapp-logo.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1000;
}

.logo {
    width: 128px;
    height: 106px;
    display: none;
}
.sa {
    margin: 0px auto 30px;
    width: 100%;
    height: max-content;
    padding: 20px;
}

.sa .tile {
    border: 1px solid #ccc;
    background-color: white;
    width: 100%;
}

.sa .tile .content {
    justify-content: space-evenly;
}

.sa .suggest {
    font-size: 1.5rem;
    color: #222;
    font-weight: bold;
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .sa .tile .title {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .sa .tile .excerpt {
        display: unset;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

@media only screen and (min-width: 800px) {
    .sa {
        width: 80%;
        padding: 0px;
    }

    .sa .suggest {
        font-size: 2rem;
        margin-top: 50px;
    }
}


@media only screen and (min-width: 1200px) {
    .sa {
        width: 1000px;
        padding: 0px;
    }

    .sa .tile .excerpt {
        -webkit-line-clamp: 2;
    }
}
#ambientes {
    display: flex;
    max-width: 1000px;
    flex-wrap: wrap;
    margin: 0 auto;
}

#ambientes .pict {
    display: none;
    flex: 0 0 66.6666%;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
}

#ambientes .pict img {
   height: 290px;
}

@media only screen and (min-width: 1000px) {
    #ambientes .pict {
        display: flex;
    }
}

#header {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    transition-duration: 200ms;
}

.home-page .background-white {
    background-color: white;
}

.home-page .background-transparent {
    background-color: transparent !important;
    box-shadow: none !important;
}

#header .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
}

#header .logo {
    height: 70px;
}

#header .navbar {
    height: 100%;
    display: none;
}

#header .nav-item {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: #222;
    text-decoration: none;
    position: relative;
}

.home-page #header.background-transparent .nav-item {
    color: #222;
}

#header .nav-item:hover {
    background: rgba(0, 0, 0, 0.05);
}

#header .dropdown {
    position: absolute;
    top: 80px;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    background: white;
    min-width: 150px;
    max-height: calc(100vh - 90px);
    /* overflow-y: scroll; */
}

#header .dropdown-link {
    display: flex;
    padding: 10px 20px;
    width: max-content;
    min-width: 100%;
    color: #222;
    text-decoration: none;
    text-transform: capitalize;
    justify-content: flex-end;
}

#header .dropdown-link:hover {
    background: rgba(0, 0, 0, 0.05);
}

.hide {
    display: none !important;
}

@media only screen and (min-width: 900px) {
    #header .navbar {
        display: flex;
    }
}
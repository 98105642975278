/* ======================================= section 10 =========================================== */

.section10 {
    padding: 30px 30px 0;
    margin-bottom: -100px;
    z-index: 501;
    position: relative;
}

.section10 .content {
    background-color: #7a1414;
    color: white;
    padding: 30px 20px;
}

.section10 .content .image-wrapper {
    display: none;
}

.section10 h2 {
    color: white;
}

.section10 p {
    color: white;
    margin-bottom: 20px;
}

.section10 ul {
    list-style-image: url(../../assets/img/section10-check.png);
    margin-left: 40px;
}

.section10 li {
    color: white;
    margin-bottom: 20px;
    text-align: justify;
}

.section10 .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 50px;
}

/* ====================================== breakpoints =========================================== */

@media only screen and (min-width: 800px) {
    .section10 .content .wrapper {
        display: flex;
        align-items: center;
    }
    .section10 .content .image-wrapper {
        display: unset;
        flex: 0 0 50%;
    }
}

@media only screen and (min-width: 1024px) {
    .section10 .content {
        padding: 30px 30px;
    }
}

@media only screen and (min-width: 1200px) {
    .section10 .content {
        padding: 30px 80px;
    }
}
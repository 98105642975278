#blog-page .main-section {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin-bottom: 50px;
}

#blog-page .main-section h2 {
    text-align: center;
    font-size: 2rem;
    color: #222;
    border-bottom: 3px solid rgba(87, 26, 22, 0.8);
    width: max-content;
    margin: 0 auto;
}

@media only screen and (min-width: 600px) {
    #blog-page .main-section {
        width: 80%;
        margin: 0 auto;
        padding: 0;
    }
}

@media only screen and (min-width: 1200px) {
    #blog-page .main-section {
        width: 1100px;
    }
}
#blog-category .main-section {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin-bottom: 50px;
}

@media only screen and (min-width: 600px) {
    #blog-category .main-section {
        width: 80%;
        margin: 0 auto;
        padding: 0;
    }
}

@media only screen and (min-width: 1200px) {
    #blog-category .main-section {
        width: 1100px;
    }
}

#blog-category .subtitle {
    display: none;
}